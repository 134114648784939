<template>
  <v-dialog v-model="dialog" max-width="450px" persistent>
    <v-card>
      <v-form @submit.prevent="save">
        <v-card-title>{{ workspaceData.id ? 'Edit' : 'Create' }} workspace</v-card-title>

        <v-card-text>
          <v-text-field label="Workspace name" v-model="workspaceData.name" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text @click="close">Close</v-btn>

          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      workspaceData: {}, // set defaults inside open
    };
  },

  methods: {
    open(data) {
      this.workspaceData = data || {
        id: null,
        name: '',
      };
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    close() {
      this.dialog = false;
      this.reject();
    },

    save() {
      this.dialog = false;
      this.resolve(this.workspaceData);
    },
  },
};
</script>
